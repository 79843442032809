import React from "react";
import Img from "gatsby-image";


class Slide extends React.Component {
    constructor(props) {
      super(props);
  
      this.handleMouseMove = this.handleMouseMove.bind(this);
      this.handleMouseLeave = this.handleMouseLeave.bind(this);
      this.handleSlideClick = this.handleSlideClick.bind(this);
      this.imageLoaded = this.imageLoaded.bind(this);
      this.slide = React.createRef();
    }
    
    handleMouseMove(event) {
      const el = this.slide.current;
      const r = el.getBoundingClientRect();
  
      el.style.setProperty('--x', event.clientX - (r.left + Math.floor(r.width / 2)));
      el.style.setProperty('--y', event.clientY - (r.top + Math.floor(r.height / 2)));
    }
    
    handleMouseLeave(event) {    
      this.slide.current.style.setProperty('--x', 0);
      this.slide.current.style.setProperty('--y', 0);
    }
    
    handleSlideClick(event) {
      
      this.props.handleSlideClick(this.props.index);
    }
    
    imageLoaded(event) {
      event.target.style.opacity = 1;
    }
    
    render() {
      const { fluid } = this.props.slide;
      const { index, current } = this.props;
      let classNames = 'slide';
      if (current === index) classNames += ' slide--current';
      else if (current - 1 === index) classNames += ' slide--previous';
      else if (current + 1 === index) classNames += ' slide--next';
          
      return (
        <li 
          ref={this.slide}
          className={classNames} 
          onClick={this.handleSlideClick}
          onMouseMove={this.handleMouseMove}
          onMouseLeave={this.handleMouseLeave}
        >
          <div className="slide__image-wrapper">
            <Img className="slide__image" fluid={fluid}/>
          </div>
        </li>
      )
    }
}
  
export default Slide;