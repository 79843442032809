import React from "react";
import { StaticQuery, graphql } from "gatsby"
import Slide from '../components/slide';

export const galleryImg = graphql`
  fragment galleryImg on File {
    childImageSharp {
        fluid {
            ...GatsbyImageSharpFluid
        }
    }
  }
`;
const ControlBtn = ({ type, title, handleClick }) => {
    return (
    <button className={`btn btn--${type}`} title={title} onClick={handleClick}>
        <svg className="icon" viewBox="0 0 24 24">
        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </svg>
    </button>
    )
}
  
class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = { current: 0 };
        this.handlePreviousClick = this.handlePreviousClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleSlideClick = this.handleSlideClick.bind(this);
    }
    handlePreviousClick() {
        const { data } = this.props ;
        const previous = this.state.current - 1;
            
        this.setState({
          current: (previous < 0) 
            ? Object.keys(data).length - 1
            : previous
        });
    }
    handleNextClick() {
        const { data } = this.props;
        const next = this.state.current + 1;
        
        this.setState({ 
          current: (next === Object.keys(data).length) 
            ? 0
            : next
        });
    }
    handleSlideClick(index) {
        if (this.state.current !== index) {
          this.setState({
            current: index
          });
        }
    }
    render() {
      const { current } = this.state;
      const { data } = this.props;
      const wrapperTransform = {
        'transform': `translateX(-${current * (100 / Object.keys(data).length)}%)`
      };
      
      return (
        React.createElement("section", { className: "section__gallery"},
        React.createElement("div", { className: "slider"},
        React.createElement("ul", { className: "slider__wrapper", style: wrapperTransform },
        Object.keys(data).map((key, index) => {
          return (
            React.createElement(Slide, {
              key: index,
              index: index,
              slide: data[key].childImageSharp,
              current: current,
              handleSlideClick: this.handleSlideClick }));
        })),
        React.createElement("div", { className: "slider__controls" },
        React.createElement(ControlBtn, {
          type: "previous",
          title: "Go to previous slide",
          handleClick: this.handlePreviousClick }),
        React.createElement(ControlBtn, {
          type: "next",
          title: "Go to next slide",
          handleClick: this.handleNextClick })))));
    }
}

export default props => (
    <StaticQuery
      query={graphql`
        query {
            tablesImg: file(relativePath: { eq: "store-tables.jpg" }) {
              ...galleryImg
            }
            barImg: file(relativePath: { eq: "store-bar.jpg" }) {
                ...galleryImg
            }
            middleImg: file(relativePath: { eq: "store-middle.jpg" }) {
              ...galleryImg
            }
            checkoutImg: file(relativePath: { eq: "store-checkout.jpg" }) {
                ...galleryImg
            }
            
            counterImg: file(relativePath: { eq: "store-counter.jpg" }) {
                ...galleryImg
            }
        }
      `}
      render={data => <Gallery data={data} {...props} />}
    />
  )