import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Logo from "../components/logo"
import Menu from "../components/menu"
import Gallery from "./gallery"
import ContactUs from "./contact"

const IndexPage = () => {
  const {languages, originalPath, language	} = useI18next();
  const data = useStaticQuery(graphql`query HeroImgQuery {
    file(relativePath: {eq: "wooden_plants-bg.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`);
  let hero = data.file ? data.file.childImageSharp.fluid : false

  return (
  <Layout>
    <SEO title="Home" />
    <section id="home" className="section__hero">
      {hero != null? <Img className="section__hero" src={hero.src} fluid={hero} />: null}
      <div className="logo">
        <Logo logoClass="logo--text"></Logo>
      </div>
      
      <div className="section__languages">
        {languages.map((lng) => {
          if (lng === language) return false;
          return (
            <Link to={originalPath} language={lng} key={lng}>
              {lng === "en"? "English": "Español"}
            </Link>
          )
        })}
      </div>
      <a href="#story"><div className="down-arrow"></div></a>
    </section>
    <section id="story" className="section__story">
        <div className="story__image"></div>
        <div className="story__text">
          <h2><Trans>Our Story</Trans></h2>
          <p><Trans>Serving a taste of El Salvador since 2003.</Trans></p>
          {/* <span>EST. 2003</span> */}
        </div>
    </section>
    <Gallery></Gallery>
    <Menu></Menu>
    <ContactUs></ContactUs>
  </Layout>
)}

export default IndexPage
