import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import { Trans } from 'gatsby-plugin-react-i18next';


//import { useStaticQuery, graphql } from 'gatsby';

const ContactUs = () => {
    const data = useStaticQuery(graphql`query MapImgQuery {
        file(relativePath: {eq: "map.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }`);
      
    return (
        <section id="contact" className="section__contact">
            <div className="contact__location">
                <div className="contact__address">
                    <h2><Trans>Visit Us</Trans></h2>
                    <p>San Francisco Cafe & Restaurant<br/> 6040 Harrison Place <br/>West New York <br/>NJ 07093</p>
                </div>
                <div className="contact__map">
                    <a href="https://goo.gl/maps/gGNRvDW1whKtQCav7" target="_blank">
                        <Img fluid={data.file.childImageSharp.fluid} />
                    </a>
                </div>
                <div className="contact__hours">
                    <h2><Trans>Opening Hours</Trans></h2>
                    <p>
                        <Trans>Monday</Trans> - <Trans>Saturday</Trans><br/>
                        6:00AM - 9:00PM<br/>
                        <Trans>Sunday</Trans><br/>
                        8:00AM - 7:00PM
                    </p>
                </div>
            </div>
            <div id="call" className="contact__channels">
                <div className="contact__phone">
                    <h2><span><Trans>Call Us</Trans></span></h2>
                    <p><a href="tel:2016621091" ><span className="icon-phone icon-social"></span>201-662-1091</a></p>
                </div>
                <div className="contact__social">
                    <h2><span><Trans>Follow Us</Trans></span></h2>
                    <p>
                        <a href="https://www.facebook.com/sanfrancafe"><span className="icon-facebook1 icon-social"></span></a>
                        <a href="https://www.instagram.com/sanfrancafenj"><span className="icon-instagram1 icon-social"></span></a>
                        <a href="https://twitter.com/SanFranCafeNJ"><span className="icon-twitter1 icon-social"></span></a>
                    </p>
                </div>
            
            </div>
            
        </section>
    )
}


export default ContactUs;


