import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

const Menu = () => {
    const data = useStaticQuery(graphql`
    query MenuQuery {
        allMenuJson {
        edges {
            node {
            tab
            id
            menus {
                header
                subheader
                items {
                description
                name
                price
                }
            }
            }
        }
        }
    }
`)['allMenuJson']['edges'];
    const buttons = typeof window !== 'undefined' ? document.getElementsByClassName('btn'): [];
    const {t} = useTranslation();
    let  menuSelect = (e) => {
        e.preventDefault();
        if ( typeof window !== 'undefined' ) {
            let menu = document.getElementsByClassName('menu--visible');
            menu[0].classList.remove('menu--visible');
            for (let button of buttons) {
                button.classList.remove('active');
            }
            document.getElementById(e.currentTarget.dataset.target).classList.add('menu--visible')
            e.currentTarget.classList.add('active');
        }
        
        
    };
    let menuTabs = [];
    let menus = data.map((menu, index) => {
        let btnClass = index == 0 ?"btn active": "btn";
        menuTabs.push(<button className={btnClass} key={index} data-target={menu['node'].id} onClick={menuSelect}><Trans>{menu['node'].tab}</Trans></button>);
        let sections = menu['node'].menus.map((section, j) => {
            let items = section.items.map((item, i) => (
                <div className="menu__item" key={i}>
                    <div className="item__header">
                        <div className="item__title"><Trans>{item.name}</Trans></div>
                        <span className="item__dots"></span>
                        <span className="item__price">${item.price}</span>
                    </div>
                    <p className="item__description"><Trans>{item.description}</Trans></p>
                </div>
            ));
            return (
                <React.Fragment key={j}>
                    <h3 ><span><Trans>{section.header}</Trans></span></h3>
                    {(section.subheader !== "") ? <h4><Trans>{section.subheader}</Trans></h4>:""}
                    {items}
                </React.Fragment>
            );
        });
        let classes = "menu__section menu";
        classes += (index === 0) ? " menu--visible": "";

        return (
            <div className={classes} id={menu['node'].id} key={index}>
                {sections}
            </div>
        );
    });
    return (
        <section id="menu" className="section__menu">
            <div className="menu__pad">
                <h2>{t('Menu')}</h2>
                <div className="menu__tabs">
                    {menuTabs}
                </div>
                {menus}
            </div>
        </section>
    )
}

export default Menu;